import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CRMAccount, CrmAccountComponent } from '../admin/crm-account/crm-account.component';
import { User } from './user';

@Injectable()
export class CRMAccountService {
  constructor(private http: HttpClient) {}

  getAllCRMAccounts(): Observable<CRMAccount[]> {
    return this.http
      .get<CRMAccount[]>(`/api/crmaccounts`)
      .pipe(map((accounts: CRMAccount[]) => accounts.map(x => new CRMAccount(x))));
  }

  getByCrmAccountId(appId: string): Observable<CRMAccount> {
    return this.http
      .get<CRMAccount>(`/api/crmaccounts/${appId}`)
      .pipe(map((account: CRMAccount) => new CRMAccount(account)));
  }

  getUsersByPartialEmailAddress(partialEmail: string): Observable<User[]> {
    return this.http
      .get<User[]>(`/api/users/${partialEmail}/partial`)
      .pipe(map((users: User[]) => users.map(x => Object.assign(new User(), x))));
  }

  save(crmAccount: CRMAccount): Observable<CRMAccount> {
    return this.http
      .put<CRMAccount>(`/api/crmaccounts/${crmAccount.AppId}`, crmAccount)
      .pipe(map((account: CRMAccount) => new CRMAccount(account)));
  }

  createApp(appName: string, appId: string): Observable<CRMAccount> {
    const newAccount = new CRMAccount();
    newAccount.Name = appName;
    newAccount.AppId = appId;

    return this.http
      .put<CRMAccount>(`/api/crmaccounts/${newAccount.AppId}`, newAccount)
      .pipe(map((account: CRMAccount) => new CRMAccount(account)));
  }
}
