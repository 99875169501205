<div id="crm-account">
    <div class="top">
        <div class="title-area">
            <div class="text">
                <svg-helper class="icon" [svgName]="'admin_tools'"></svg-helper>
                <div class="title">Tools > CRM Accounts</div>
            </div>
        </div>
        <div class="top-btn priority-positive anchor" (click)="save()">
            <svg-helper class="icon" [svgName]="'download'"></svg-helper>
            <span class="label">Save</span>
        </div>
    </div>
    <div class="bottom">
        <div class="form-box">
            <div class="box-header transparent">
                <svg-helper class="icon" [svgName]="'circle_arrow_right'"></svg-helper>
                <div class="label">Create CRM Account</div>
            </div>
            <div class="box-content">
                <div class="row align-bottom">
                    <div class="field col-xs-2">
                        <label>App Name</label>
                        <input [(ngModel)]="newAppName" />
                    </div>
                    <div class="field col-xs-2">
                        <label>App Id</label>
                        <input [(ngModel)]="newAppId" />
                    </div>
                    <div class="field col-xs-2">
                        <button class="btn standard-positive" (click)="createNewApp()">Create</button>
                        <tooltip message="You can rename an existing App by entering in a new App Name and existing App Id"></tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-box">
            <div class="box-header transparent">
                <svg-helper class="icon" [svgName]="'circle_arrow_right'"></svg-helper>
                <div class="label">Update CRM Account</div>
            </div>
            <div class="box-content">
                <div class="row align-bottom">
                    <div class="field col-xs-2 fadeIn">
                        <div class="label">CRM Account</div>
                        <select name="crmSelect" [(ngModel)]="appId">
                            <option *ngFor="let crmAccount of crmAccounts" [value]="crmAccount.AppId">{{crmAccount.Name}}</option>
                        </select>
                    </div>
                    <div class="field col-xs-2 fadeIn">
                        <button class="btn standard-positive" (click)="getCRM()">Retrieve</button>
                    </div>
                </div>
                <div class="row" [class.disabled]="!crmAccount">
                    <div class="field col-xs-3 fadeIn">
                        <div class="label">Clients</div>
                        <div class="flex-row align-center">
                            <input [ngModel]="clientEmail" (ngModelChange)="filterClientEmail($event)" list="client-email-list" />
                            <datalist id="client-email-list">
                                <option *ngFor="let client of allClients" [value]="client.Email"></option>
                            </datalist>
                            <button class="btn standard-positive" (click)="addClient()">+</button>
                        </div>
                        <div class="flex-row align-center justify-between user" *ngFor="let client of crmAccount?.Clients">
                            <div>{{client.Email}}</div>
                            <button class="btn standard-negative" (click)="removeClient(client)">-</button>
                        </div>
                    </div>
                    <div class="field col-xs-3 fadeIn">
                        <div class="label">Employees</div>
                        <div class="flex-row align-center">
                            <input [(ngModel)]="employeeEmail" (ngModelChange)="filterEmployeeEmail($event)" list="employee-email-list" />
                            <datalist id="employee-email-list">
                                <option *ngFor="let employee of allEmployees" [value]="employee.Email"></option>
                            </datalist>
                            <button class="btn standard-positive" (click)="addEmployee()">+</button>
                        </div>
                        <div class="flex-row align-center justify-between user" *ngFor="let employee of crmAccount?.Employees">
                            <div>{{employee.Email}}</div>
                            <button class="btn standard-negative" (click)="removeEmployee(employee)">-</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>