import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnInit } from '@angular/core';
import { CRMAccountService } from '../../entities/crm-account.service';
import { debounceTime, lastValueFrom, mergeMap } from 'rxjs';
import { ToastService } from '../../shared/toast/toast.service';

@Component({
  selector: 'crm-account',
  templateUrl: './crm-account.component.html',
  styleUrls: ['./crm-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class CrmAccountComponent implements OnInit {
  crmAccounts = new Array<CRMAccount>();
  crmAccount: CRMAccount;
  clientEmail: string;
  employeeEmail: string;
  appId: string;
  allClients: CrmUserDTO[];
  allEmployees: CrmUserDTO[];
  newAppName: string;
  newAppId: string;

  constructor(private crmAccountService: CRMAccountService, private toastService: ToastService) {}

  async ngOnInit() {
    this.toastService.showLoading();
    this.crmAccounts = await lastValueFrom(this.crmAccountService.getAllCRMAccounts());
    console.log(this.crmAccounts);
    this.toastService.hideLoading();
  }

  async getCRM() {
    this.crmAccount = await lastValueFrom(this.crmAccountService.getByCrmAccountId(this.appId));
    console.log(this.crmAccount);
  }

  createNewApp() {
    if (!this.newAppName) {
      this.toastService.showMessage('Please enter in a valid App name');
      return;
    }

    if (!this.newAppId) {
      this.toastService.showMessage('Please enter in a valid App Id');
      return;
    }

    this.crmAccountService.createApp(this.newAppName, this.newAppId).subscribe(account => {
      this.toastService.showSuccess('New app added');
      this.crmAccounts.unshift(account);
      this.appId = null;
      this.crmAccount = null;
    });
  }

  addClient() {
    const client = this.allClients.find(x => x.Email === this.clientEmail);
    if (client) {
      if (!this.crmAccount.Clients.find(x => x.Id === client.Id)) {
        this.crmAccount.Clients.push(client);
      }
    }
  }

  addEmployee() {
    const employee = this.allEmployees.find(x => x.Email === this.employeeEmail);
    if (employee) {
      if (!this.crmAccount.Employees.find(x => x.Id === employee.Id)) {
        this.crmAccount.Employees.push(employee);
      }
    }
  }

  removeEmployee(employee: CrmUserDTO) {
    const index = this.crmAccount.Employees.findIndex(x => x.Id === employee.Id);
    if (index > -1) {
      this.crmAccount.Employees.splice(index, 1);
    }
  }

  removeClient(client: CrmUserDTO) {
    const index = this.crmAccount.Clients.findIndex(x => x.Id === client.Id);
    if (index > -1) {
      this.crmAccount.Clients.splice(index, 1);
    }
  }

  save() {
    this.toastService.showLoading();
    this.crmAccountService.save(this.crmAccount).subscribe(x => {
      this.toastService.showSuccess('Saved');
    });
  }

  filterClientEmail(email: string) {
    this.clientEmail = email;

    if (this.clientEmail.length < 4) return;

    this.crmAccountService
      .getUsersByPartialEmailAddress(email)
      .pipe(
        debounceTime(200),
        mergeMap(x => (this.allClients = x))
      )
      .subscribe();
  }

  filterEmployeeEmail(email: string) {
    this.employeeEmail = email;

    if (this.employeeEmail.length < 4) return;

    this.crmAccountService
      .getUsersByPartialEmailAddress(email)
      .pipe(
        debounceTime(200),
        mergeMap(x => (this.allEmployees = x))
      )
      .subscribe();
  }
}

export class CRMAccount {
  AppId: string;
  Name: string;
  Employees: Array<CrmUserDTO>;
  Clients: Array<CrmUserDTO>;

  constructor(crmAccount?: CRMAccount) {
    if (crmAccount) {
      Object.assign(this, crmAccount);
      this.Employees = crmAccount?.Employees?.map(x => new CrmUserDTO(x)) || [];
      this.Clients = crmAccount?.Clients?.map(x => new CrmUserDTO(x)) || [];
    }
  }
}

export class CrmUserDTO {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;

  constructor(crmUserDTO?: CrmUserDTO) {
    if (crmUserDTO) {
      Object.assign(this, crmUserDTO);
    }
  }
}
